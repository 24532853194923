import React from 'react';
import Image from 'next/image';

import { IImage, ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { Fade } from 'components/animations/Fade';

import s from './FeaturedArticles.module.scss';

type Props = {
  title?: string;
  date?: string;
  tagline?: string;
  link: ILink;
  image?: IImage;
  isVisible?: boolean;
  index?: number;
};

export const FeaturedArticlesItem = ({
  title,
  date,
  tagline,
  link,
  image,
  isVisible,
  index = 0,
}: Props) => {
  return (
    <Link className={s.item} {...link}>
      <Fade isVisible={isVisible} delay={0.15 * index}>
        {image && (
          <div className={s.item__image}>
            <div className={s.item__aspect} />
            <Image
              className={s.item__img}
              src={image.shaped?.url ?? image.thumbnail?.url ?? image.url}
              // width={image.width}
              // height={image.height}
              layout="fill"
              alt=""
            />
          </div>
        )}
        <div className={s.item__text}>
          <div className={s.item__header}>
            {tagline && <div className={s.item__tagline}>{tagline}</div>}
            {date && <div className={s.item__date}>{date}</div>}
          </div>
          {/* {tagline && <div className={s.item__tagline}>{tagline}</div>} */}
          {title && <h3 className={s.item__title}>{title}</h3>}
        </div>
      </Fade>
    </Link>
  );
};
