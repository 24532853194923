import React from 'react';
import { FeaturedArticles } from 'components/featured-articles/FeaturedArticles';
import { FeaturedArticlesItem } from 'components/featured-articles/FeaturedArticlesItem';
import { RichText } from 'components/rich-text/RichText';

export const ArticleSlice = ({
  title,
  tagline,
  text,
  buttonText,
  link,
  items: { items },
  simple,
  tags,
  matchAll,
  isSlice,
}) => {
  return (
    <FeaturedArticles
      title={title}
      tagline={tagline}
      text={RichText.render(text)}
      buttonText={buttonText}
      design={simple ? 'simple' : 'default'}
      link={link}
      isSlice={isSlice}
    >
      {items?.map((item, index) => (
        <FeaturedArticlesItem
          key={index}
          tagline={item.tagline}
          image={!simple && item.image}
          title={item.title}
          date={item.date}
          link={item.link}
        />
      ))}
    </FeaturedArticles>
  );
};

export default ArticleSlice;
